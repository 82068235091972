/* eslint-disable camelcase */
export const en = {
  HelpDocs: {
    contractTitle: "Contracts",
    contractName1: "Psychological Contract 2024",
    contractName2: "Psychological Contract RU 2024",
    contractName3: "Psychological Contract UA 2024",
    contractName4: "Personal Data Protection Policy - Information Clause 2024",
    handbookTitle: "Handbook and Test Catalog",
    handbookName1: "Characteristics of Diagnostic Areas",
    handbookName2: "Psychomotor Tests",
    handbookName3: "Cognitive Tests",
    handbookName4: "Personality Tests",
    handbookName5: "Test Catalog 03-2024",
    modulesTitle: "Interview Modules",
    modulesName1: "Health Situation",
    modulesName2: "Social and Family Situation",
    modulesName3: "Observation",
    modulesName4: "Detectives Protection Weapons MTD I",
    modulesName5: "License MTD II",
    modulesName6: "Occupational Medicine MTD III",
    modulesName7: "Service Drivers MTD III",
    modulesName8: "Working at Heights MTD III",
    modulesName9: "Forklifts MTD III",
    modulesName10: "Transport Psychology Vehicle Operation MTD IV"
  },
  Format: {
    ViewDateTime: "MM/DD/YYYY HH:mm",
    FormDateFormat: "MM/DD/YYYY",
    ExamineeViewDateFormat: "MM/DD/YYYY",
  },
  Buttons: {
    accept: "Agree",
    addExaminee: "Add participant",
    addDirectory: "Add folder",
    assign: "Assign",
    alreadyAssigned: "Assigned",
    assignExamination: "Assign assessment",
    alreadyAssignedExamination: "Assessment in progress",
    back: "Back",
    backToLogin: "Back to login page",
    begin: "Start",
    cancel: "Cancel",
    change: "Change",
    clear: "Clear",
    close: "Close",
    confirm: "Confirm",
    continue: "Next",
    createExamination: "Create assessment",
    delete: "Delete",
    deleteExaminee: "Delete participant",
    downloadAll: "Download all",
    edit: "Edit participant",
    editExaminee: "Edit participant",
    examinationWithNoLawBase: "Assessment without a legal basis",
    examinationWithLawBase: "Assessment with a legal basis",
    exit: "Exit",
    generate: "Generate",
    generateAndPrint: "Generate and print",
    go: "Go",
    goToDashboard: "Go to user panel",
    login: "Login",
    move: "Move",
    moveExaminee: "Move participant",
    moveExaminees: "Move participants",
    ok: "Ok",
    print: "Print",
    printContract: "Print contract",
    save: "Save",
    savePassword: "Save password",
    savePin: "Save PIN",
    saveAndPrint: "Save and print",
    saveSettings: "Save settings",
    setPin: "Set PIN",
    search: "Search",
    setPassword: "Set password",
    setNewPassword: "Set new password",
    sendLink: "Send link",
    skipTests: "Skip tests",
    understood: "Understood",
    addPsychLab: "Add customer",
    addPsychLabLic: "Add license",
    addPsychUser: "Add user",
    addUser: "Add user",
    deletePsychLab: "Delete customer",
    deletePsychLabLic: "Delete license",
    editPsychLab: "Edit customer",
    editPsychLabLic: "Edit license",
    editPsychLabUser: "Edit user",
    exportActivities: "Export to XLS",
    loginPsychLab: "Log in to client account",
    showActivities: "Show",
    enable2FA: "Enable",
    disable2FA: "Disable",
    manage2FA: "Manage 2FA",
    verify: "Verify",
  },
  Common: {
    menu: {
      contact: "Help",
      dashboard: "User panel",
      folders: "Examinee records",
      search: "Search",
      logout: "Log out",
      settings: "Settings",
      psychlabs: "Client records",
      breadcrumbsPsychLab: "Client records"
    },
    seo: {
      addExaminee: "Add participant",
      dashboard: "User panel",
      editExaminee: "Edit participant",
      helpdesk: "Help",
      helpdeskDocuments: "Help documents",
      judgement: "Judgment",
      login: "Login",
      onboarding: "Onboarding",
      report: "Report",
      resetPassword: "Reset password",
      search: "Search",
      settings: "Settings",
      examinationCard: "Examination card",
      addPsychLab: "Add client account",
      addPsychLabLic: "Add license",
      folders: "Folders",
      examinationAssignment: "Examination assignment with legal basis",
      setNewPassword: "Set new password"
    },
    warningPopup: {
      text: "The changes you make will not be saved",
      header: "Are you sure you want to leave?",
      textSave: "Required fields may also be located on other tabs",
      headerSave: "Please complete all required fields!",
      promptLogout: "You will be automatically logged out in PROMPT_TIME_MINUTES minute due to inactivity."
    },
    logAdminAsUserPopup: {
      text: "Failed to log in to client account",
      header: "Error"
    },
    deleteExamineePopup: {
      text: "Once the participant is deleted, it will not be possible to restore the data",
      header: "Are you sure you want to delete the participant?",
    },
    twoFactorAuth: "Two-Factor Authentication",
  },
  Contact: {
    header: "Technical Support",
  },
  Cookies: {
    header: "Permission to use cookies",
    consent: `This website uses cookies, which are used to ensure the best possible performance of this application. If you agree to saving the information contained in cookies, click the I AGREE button. If you do not consent, you will not be able to use the application and perform the assigned procedures.`,
  },
  Dashboard: {
    header: "Recently added participants",
    footer: {
      lastLogin: "Last login",
      lastFailedLogin: "Last failed login",
      passwordExpired: "Your password will expire in",
      day: "day",
      days: "days",
    },
    emptyState: {
      header: "No recently added participants",
      info: "To see the list of assessment, assign at least one assessment.",
    },
    kebabMenu: {
      print: "Print contract",
      profile: "Go to the participant's profile",
    },
  },
  AdminDashboard: {
    header: "Client Records",
    emptyState: {
      header: "No client accounts added yet",
      info: "To see the list of clients, add at least one account."
    },
    emptyStateUsers: {
      header: "No user accounts added yet",
      info: "To see the list of users, add at least one user account."
    },
    emptyStateLicense: {
      header: "No licenses added yet",
      info: "To see the license, please add it."
    },
    kebabMenu: {
      print: "Print contract",
      profile: "Go to examinee profile"
    },
    PsychologicalLabsList: {
      labels: {
        name: "Client name",
        clientId: "Client ID",
        status: "Status",
        psychCnt: "Number of users",
        createDate: "Creation date",
        expireDate: "Account expiry date",
        details: "Details",
        usersCnt: "Number of users",
        examineesCnt: "Number of examinees",
        examinationsCnt: "Number of examinations",
        taskPerPage: "Rows per page:",
        all: "all",
        of: "of",
        moreThen: "more than"
      },
      labStatus: {
        enabled: "active",
        disabled: "inactive"
      }
    },
    Licence: {
      labels: {
        methodologyList: "Available methodologies",
        testsList: "Available tests",
        licenseDate: "License issue date",
        expireDate: "License expiry date"
      },
      deletePopup: {
        headerText: "Are you sure you want to delete the license?",
        contentText: "After deleting the license, it will not be possible to assign examinations",
        confirmationText: "Yes, I want to delete the license"
      }
    },
    PsychLab: {
      deletePopup: {
        headerText: "Are you sure you want to delete the client account?",
        contentText: "After deleting the client account, it will not be possible to restore the data",
        confirmationText: "Yes, I want to delete the client account"
      }
    },
    StatsList: {
      statFileName: "examination_statistics.xlsx"
    },
    ActivitiesList: {
      labels: {
        date: "Date",
        action: "Action",
        executor: "Executor",
        context: "Description",
        status: "Status",
        errorMsg: "Error description"
      },
      labStatus: {
        success: "ok",
        error: "error"
      }
    },
    UsersList: {
      deletePopup: {
        headerText: "Are you sure you want to delete the user account?",
        contentText: "After deleting the user account, it will not be possible to restore it",
        confirmationText: "Yes, I want to delete the user account"
      },
      labels: {
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        examineeCnt: "Number of participants",
        examinationCnt: "Number of examinations",
        isActive: "Status",
        isStaff: "Role"
      },
      labStatus: {
        enabled: "active",
        disabled: "inactive",
        adminRole: "administrator",
        superUserRole: "super admin",
        userRole: "user",
        userBaseRole: "registrar"
      },
      toolTip: {
        edit: "edit",
        delete: "delete"
      }
    },
    PsychLabManagment: {
      breadcrumbs: {
        add: "Add client account",
        edit: "Edit client account",
        addLicense: "Add license",
        editLicense: "Edit license",
        addUser: "Add user account",
        editUser: "Edit user account",
        dashboard: "User panel",
        psychLabs: "Client records"
      },
      header: {
        add: "Add client account",
        edit: "Edit client account",
        addUser: "Add user account",
        editUser: "Edit user account",
        addLicense: "Add license",
        editLicense: "Edit license"
      },
      PsychLabView: {
        labels: {
          details: "Details",
          download: "Download",
          edit: "Edit",
          generate: "Generate",
          name: "Test name",
          nameFile: "File name",
          print: "Print",
          result: "Result",
          type: "Document type"
        },
        tabs: {
          psychLabData: "Client data",
          license: "License",
          users: "Users",
          activityLogs: "Activity logs",
          activityLogsFile: "activity_logs",
          activityId: "id",
          activityDate: "date",
          activityAction: "action name",
          activityStatus: "status",
          activityContext: "description",
          activityExecutor: "executor",
          stats: "Statistics",
          theme: "Appearance"
        }
      }
    },
  },
  ExaminationAssignmentView: {
    header: "Assign assessment",
    labels: {
      chooseExaminationType: "Select an assessment type",
      chooseLawBase: "Select a legal basis",
      description: "Description",
      legalBase: "Legal basis",
      reason: "Purpose of the assessment",
      suggestedTestMethods: "PROPOSED METHODICAS",
      methodologyTest2Safe: "TEST2SAFE - psychological testing methodology for security staff and detectives",
      methodologyTest2Drive: "TEST2DRIVE - methodology for conducting driver examinations in transport psychology",
      methodologyTest2Arms: "TEST2ARMS - psychological testing methodology, licensed activity",
      methodologyTest2Work: "TEST2WORK - psychological testing methodology in occupational medicine"
    },
    popup: {
      header:
        "Some tests recommended for the selected methodic have been disabled.",
      content: "Are you sure you want to disable these tests?",
      checkbox: "Remember your selection",
    },
    license: "The test is not available in the assigned license",
  },
  PsychologicalTests: {
    psychomotor: "Psychomotor tests",
    cognitive: "Cognitive tests",
    personality: "Personality tests",
  },
  ExamineeList: {
    labels: {
      examineLastDate: "Last assessment date",
      contract: "Contract",
      examine: "Assessment",
      firstName: "First name",
      folder: "Folder",
      allFinishExam: "Assigned / done",
      lastName: "Lastname",
      peselSlashIdentifier: "PESEL / ID",
      profile: "View profile",
      added: "Added",
    },
  },
  ExaminationList: {
    withoutLegalBasis: "Assessment without a legal basis",
    labels: {
      assign: "Assigned",
      assignDate: "Assigned date",
      create: "Created",
      createDate: "Created date",
      contract: "Contract",
      documentation: "Documentation",
      end: "Ended",
      endDate: "Ended date",
      interview: "Interview",
      identificator: "Assessment ID",
      language: "Language",
      nameArchiveExamination: "Battery name",
      nameExamination: "Assessment name",
      start: "Started",
      startDate: "Started date",
    },
    kebabMenu: {
      delete: "Delete assessment",
    },
    deletePopup: {
      headerText: "Are you sure you want to delete the assessment?",
      contentText: "Once an assessment is deleted, it will not be possible to restore it",
      confirmationText: "Yes, I want to delete the assessment",
    },
    toolTip: {
      contract: "Generate contract",
      interview: "Interview",
      documentation: "Documentation",
      delete: "Delete",
    },
  },
  ArchiveExaminationList: {
    emptyState: {
      info: "There are no archival assessments assigned to this participant",
    },
  },
  ExaminationView: {
    header: "Dokumentation",
    breadcrumbs: {
      archive: "Archival assessments",
    },
    details: {
      createDate: "Created date",
      endDate: "Ended date",
      identificator: "Assessment ID",
      language: "Language",
      name: "Assessment name",
      startDate: "Started date",
      type: "Battery name",
    },
    protocols: {
      language: "Protocols language",
      testProtocol: "Test protocol",
    },
    labels: {
      details: "Details",
      download: "Download",
      edit: "Edit",
      generate: "Generate",
      name: "Test name",
      nameFile: "File name",
      print: "Print",
      result: "Result",
      type: "Document type",
    },
    tabs: {
      documentation: "Documentation",
      protocols: "Protocols",
      reports: "Reports",
    },
    common: {
      unavailableInLanguageOption: "Not available in the selected language version.",
    },
    aimsKind: {
      aimKind_1: "Psychological opinion - detectives",
      aimKind_3: "Certificate - physical security worker",
      aimKind_8: "Appeal certificate - physical security worker",
      aimKind_9: "Certificate - firearms permit",
      aimKind_10: "Certificate - licensed activity for military or police purposes",
      aimKind_11: "Certificate - licensed activity for civilian purposes",
      aimKind_12: "Appeal certificate - licensed activity for civilian purposes",
      aimKind_13: "Certificate - occupational medicine",
      aimKind_14: "Certificate - occupational medicine mining and mining rescue",
      aimKind_15: "Certificate - occupational medicine train driver license",
      aimKind_16: "Certificate - driver category C1, C1+E, C, C+E, D1, D1+E, D and D+E or tram driving",
      aimKind_17: "Certificate - driver license reinstatement category AM, A1, A2, A, B1, B, B+E and T",
      aimKind_18: "Certificate - driver license reinstatement psychological contraindications",
      aimKind_19: "Certificate - license extension category C1, C1+E, C, C+E, D1, D1+E, D and D+E or tram driving",
      aimKind_20: "Certificate - driver post-accident examination",
      aimKind_21: "Certificate - driver examination after alcohol or similar substances consumption",
      aimKind_22: "Certificate - driver examination after exceeding 24 points",
      aimKind_23: "Certificate - driver examination after at least 2 offenses",
      aimKind_24: "Certificate - driver examination psychological contraindications",
      aimKind_25: "Certificate - emergency vehicle driver",
      aimKind_26: "Certificate - driver post-accident examination (referred by starost)",
      aimKind_27: "Certificate - performing instructor duties",
      aimKind_28: "Certificate - performing examiner duties",
      aimKind_29: "Certificate - driver student or candidate for secondary school category C or C+E",
      aimKind_30: "Certificate - driver student or candidate for vocational course category C or C+E",
      aimKind_31: "Certificate - professional driver referred by employer",
      aimKind_32: "Certificate - self-employed professional driver"
    },
  },
  ExamineeView: {
    anonymity: "Anonymous",
    userData: {
      defaultAnonFirstName: "Examinee",
      defaultAnonLastName: "Anonymous",
      pesel: "PESEL",
      sex: "Gender",
      birthDate: "Date of birth",
      address: "Address",
      education: "Education",
      drivingLicense: "Driving license category",
      identityCard: "Identity document",
      serialNumber: "Serie and number",
      identificator: "ID",
      drivingLicenseIssuer: "The entity issuing the driving license",
      examineName: "FirstName and Lastname",
    },
    drivingLicense: {
      am: "AM",
      a1: "A1",
      a2: "A2",
      a: "A",
      b1: "B1",
      b: "B",
      be: "B+E",
      c: "C",
      c1: "C1",
      c1e: "C1+E",
      ce: "C+E",
      d: "D",
      d1: "D1",
      d1e: "D1+E",
      de: "D+E",
      t: "T",
      tramwaj: "TRAMWAY",
    },
    tabs: {
      archive: "Archival assessments",
      data: "Participant data",
      examination: "Assessments",
    },
    kebabMenu: {
      edit: "Edit participant data",
      delete: "Delete participant",
    },
    emptyState: {
      header: "There are no assessments assigned to this participant",
      info: "To see the list of assessment, assign at least one assessment.",
    },
    translations: {
      passport: "passport",
      id_card: "ID card",
      male: "male",
      female: "female",
      primary: "primary education",
      lowerSecondary: "junior high education",
      basicVocational: "basic vocational education",
      basicIndustry: "basic industry education",
      secondaryIndustry: "secondary vocational education",
      secondary: "secondary education",
      higher: "higher education",
    },
  },
  ExamineeManagment: {
    breadcrumbs: {
      add: "Add participant",
      edit: "Edit participant",
      dashboard: "User panel",
      directories: "Managing Participant folders",
    },
    header: {
      add: "Add participant",
      edit: "Edit participant",
    },
  },
  Forms: {
    adminFields: {
      methodologyTlt: "Methodology",
      testPsychMotorTlt: "psychomotor tests",
      testCogTlt: "cognitive tests",
      testPersonTlt: "personality tests",
      address: "Street and house number",
      name: "Name",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      password: "Password",
      pin: "PIN code",
      isActive: "Account status",
      isStaff: "Role",
      dateExpire: "Account expiry date",
      dateLicExpire: "License expiry date",
      licMethodologies: "Available methodologies",
      licTests: "Available tests",
      city: "City",
      postalCode: "postal code",
      clientId: "Client ID",
      cin: "Registration number",
      tin: "Tax ID",
      theme: "Appearance",
      examineesCnt: "Number of subjects",
      usersCnt: "Number of users",
      dateFrom: "range from",
      dateTo: "range to",
      activityName: "activity",
      activityDetails: "Details",
      status: "status",
    },
    adminOptions: {
      theme: {
        optimisTheme: "optimis",
        defaultTheme: "default",
      },
      status: {
        active: "active",
        disabled: "inactive",
      },
      admin: {
        admin: "administrator",
        user: "user",
        baseuser: "registrar",
      },
    },
    adminSnackbar: {
      required: "Please fill in all required fields.",
      email_unique_error: "This email address already exists in the database!",
      email_wrong_error: "The provided email address is invalid!",
    },
    fields: {
      address: "Address",
      additionalInfo: "Additional information",
      appealAuthority: "Name of the institution",
      appealDeadline: "Date",
      birthDate: "Date of birth",
      city: "City",
      country: "Country",
      diffrentIdentificationNumber: "Different ID",
      directory: "Folder",
      documentDate: "Date of issue",
      drivingLicense: "Driving license category",
      drivingLicenseIssuer: "Entities issuing driving licenses",
      education: "Education",
      identificationNumber: "PESEL",
      identityDocumentType: "Identity document",
      identityDocumentNumber: "Serie and number",
      interval: "Select a confidence interval",
      judgmentNumber: "Judgment number",
      languageReport: "Select report language",
      legalBasis: "Legal basis",
      months: "months",
      name: "First name",
      nextExamination: "Date of next examination",
      postalCode: "Zip code",
      recipient: "Select a recipient",
      sex: "Gender",
      standard: "Select norm",
      surname: "Last name",
      years: "Years",
      year: "Year",
      examineeRegistryId: "Number in the register",
      age: "Age",
      date: "Date",
      institutionName: "Name of the institution",
      lang: "Language",
    },
    options: {
      activitiesStatus: {
        ok: "ok",
        user_error: "user error",
        server_error: "server error",
      },
      lang: {
        // English
        polish: "Polish",
        english: "English",
        espaniol: "Spanish",
        mexico: "Mexican Spanish",
        russian: "Russian",
        ukrain: "Ukrainian",
      },
      activities: {
        ALL: "all",
        AUDIT_ERROR: "Activity log error",
        ALL_ITEMS: "All activities",
        APP_REGISTRATION: "Application registration",
        TEST_RESULT_SUBMITTED: "Test results submitted",
        CONSOLE_PIN_VERIFICATION: "PIN verification on user console",
        SAVE_AGREEMENTS: "Saving consent for examination and personal data processing",
        TEST_SKIPPED: "Test skipped on console",
        EXAMINATION_STARTED: "Examination started",
        EXAMINATION_TERMINATED: "Examination terminated",
        ADMIN_LOGGED_OUT: "Administrator logged out",
        ADMIN_CHANGE_LAB_ID: "Administrator logged into client account",
        USER_LOGGED_IN: "User logged in",
        USER_LOGGED_OUT: "User logged out",
        PASSWORD_CHANGE_REQUEST: "Password change request",
        PASSWORD_CHANGE: "Password change",
        PASSWORD_CHANGE_EXPIRED: "Password change after expiration",
        PIN_CHANGE: "PIN change",
        DIRECTORY_CREATED: "Directory created",
        DIRECTORY_NAME_CHANGED: "Directory name changed",
        DIRECTORY_DELETED: "Directory deleted",
        EXAMINEE_ADDED: "Subject added",
        EXAMINEE_DATA_CHANGE: "Subject data changed",
        EXAMINEE_DELETED: "Subject data deleted",
        EXAMINEE_MOVED_TO_DIFFERENT_DIRECTORY: "Subject data moved to different directory",
        EXAMINATION_ASSIGNED: "Examination assigned",
        EXAMINATION_DELETED: "Examination deleted",
        EXAMINATION_REPORT_DOWNLOADED: "Report documents downloaded",
        CONTRACT_DOWNLOADED: "Contract downloaded",
        EXAMINATION_PROTOCOL_DOWNLOADED: "Examination protocol downloaded",
        EXAMINATION_PROTOCOLS_ZIP_DOWNLOADED: "Examination protocols ZIP downloaded",
        INTERVIEW_CHANGED: "Interview changed",
        INTERVIEW_DOWNLOADED: "Interview downloaded",
        ADMIN_LICENCE_ADDED: "Client account license added",
        ADMIN_LICENCE_CHANGED: "Client account license changed",
        ADMIN_LICENCE_DELETED: "Client account license deleted",
        ADMIN_PSYCHOLOGICAL_LAB_ADDED: "Client account added",
        ADMIN_PSYCHOLOGICAL_LAB_DELETED: "Client account deleted",
        ADMIN_PSYCHOLOGICAL_LAB_CHANGED: "Client account data modified",
        ADMIN_USER_ADDED: "User added to client account",
        ADMIN_USER_DELETED: "User deleted from client account",
        ADMIN_USER_CHANGED: "Client account user data changed",
        ADMIN_LOGGED_IN: "Administrator logged in",
      },
      norms: {
        norm_1: "OPT_1.0.PL",
        norm_2: "OPT_1.0.UA",
        norm_3: "OPT_1.0.RU",
      },
      bool: {
        true: "Yes",
        false: "No",
      },
      education: {
        primary: "Primary education",
        lowerSecondary: "Junior high education",
        secondary: "Secondary education",
        basicVocational: "Basic vocational education",
        basicIndustry: "Basic industry education",
        secondaryIndustry: "Secondary vocational education",
        higher: "Higher education",
      },
      identityDocument: {
        idCard: "ID card",
        passport: "Passport",
      },
      sex: {
        female: "Female",
        male: "Male",
      },
      country: {
        poland: "Poland",
        austria: "Austria",
        bulgaria: "Bulgaria",
        croatia: "Croatia",
        cyprus: "Cyprus",
        czechRepublic: "Czech Republic",
        denmark: "Denmark",
        estonia: "Estonia",
        finland: "Finland",
        france: "France",
        greece: "Greece",
        spain: "Spain",
        netherlands: "Netherlands",
        ireland: "Ireland",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        latvia: "Latvia",
        malta: "Malta",
        germany: "Germany",
        portugal: "Portugal",
        romania: "Romania",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        sweden: "Sweden",
        hungary: "Hungary",
        italy: "Italy",
        afghanistan: "Afghanistan",
        albania: "Albania",
        angola: "Angola",
        saudiArabia: "Saudiarabia",
        argentina: "Argentina",
        armenia: "Armenia",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        bolivia: "Bolivia",
        bosniaAndHerzegovina: "Bosnia and Herzegovina",
        brazil: "Brazil",
        chile: "Chile",
        china: "China",
        chad: "Chad",
        montenegro: "Montenegro",
        democraticRepublicOfTheCongo: "Democratic Republic of the Congo",
        dominicanRepublic: "Dominican Republic",
        ecuador: "Ecuador",
        ethiopia: "Ethiopia",
        gambia: "Gambia",
        ghana: "Ghana",
        georgia: "Georgia",
        guatemala: "Guatemala",
        honduras: "Honduras",
        india: "India",
        indonesia: "Indonesia",
        iran: "Iran",
        iceland: "Iceland",
        israel: "Israel",
        jamaica: "Jamaica",
        japan: "Japan",
        yemen: "Yemen",
        jordan: "Jordan",
        cambodia: "Cambodia",
        cameroon: "Cameroon",
        qatar: "Qatar",
        kazakhstan: "Kazakhstan",
        congo: "Congo",
        southKorea: "Southkorea",
        laos: "Laos",
        northernMacedonia: "Northern Macedonia",
        madagascar: "Madagascar",
        malaysia: "Malaysia",
        mali: "Mali",
        mauritania: "Mauritania",
        mexico: "Mexico",
        myanmar: "Myanmar",
        moldova: "Moldova",
        mozambique: "Mozambique",
        namibia: "Namibia",
        niger: "Niger",
        nigeria: "Nigeria",
        nicaragua: "Nicaragua",
        norway: "Norway",
        oman: "Oman",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        centralAfricanRepublic: "Central African Republic",
        russia: "Russia",
        rwanda: "Rwanda",
        elSalvador: "Elsalvador",
        senegal: "Senegal",
        serbia: "Serbia",
        somalia: "Somalia",
        unitedStatesOfAmerica: "United States of America",
        sudan: "Sudan",
        southSudan: "Southsudan",
        switzerland: "Switzerland",
        tajikistan: "Tajikistan",
        thailand: "Thailand",
        turkey: "Turkey",
        uganda: "Uganda",
        ukraine: "Ukraine",
        uruguay: "Uruguay",
        venezuela: "Venezuela",
        unitedKingdom: "United Kingdom",
        vietnam: "Vietnam",
        zambia: "Zambia",
        unitedArabEmirates: "United Arab Emirates",
        zimbabwe: "Zimbabwe",
      },
      recipient: {
        examinee: "Participant",
        psychologist: "Psychologist",
        specialist: "Specialist",
      },
      languagesDocumentation: {
        pl: "polish",
        en: "english",
      },
    },
    extraFields: "Supplementary data",
    requiredFields: "Mandatory data",
    anonymous: "Anonymous test",
    snackbar: {
      peselLength: "PESEL must consist of 11 digits",
      peselNumber: "PESEL may only consist of numbers",
      required: "All required fields must be filled out",
    },
  },
  FormValidation: {
    max: "The name cannot contain more than 128 characters",
    required: "This field is required",
    Pin: {
      length: "Field must have only 4 digit characters",
    },
    Password: {
      length: "Minimum 8 characters",
      characters:
        "Must contain a capital letter, a special character and a number",
    },
  },
  Helpdesk: {
    header: "Help",
    subheader: "Help desk",
    help: "Remote help",
    contact: "Contact",
    phoneNo: "phone",
    mobile: "mobile",
    documents: {
      header: "Help documents",
      labels: {
        download: "Download",
        name: "Document name",
      },
    },
  },
  Interview: {
    header: "Interview",
    inputAdditionalInformation: "Enter additional information",
    inputHoursOfWork: "Enter hours worked in the last 24 hours",
    years: "Years",
    months: "Months",
    hours: "Hours",
    tabs: {
      currentPsychophysicalState: "Health situation",
      socialAndFamilySituation: "Social and family situation",
      observation: "Observation",
      drivingVehicles: "Vehicle operation",
      occupationalMedicine: "Occupational medicine",
      securityAndDetectives: "Security and detective services",
      licensedActivities: "Licensed activities",
    },
    questions: {
      questionAnswers: {
        yes: "yes",
        no: "no",
      },
      questionTexts: {
        when: "When?",
        sinceWhen: "Since when?",
        forHowLong: "Until when?",
        why: "For what reason?",
        whatAreTheConsequences: "What were the health consequences?",
        describeTheCircumStancesOfUsage: "Describe the circumstances of use",
      },
      methodologies: {
        currentPsychophysicalState: {
          headers: {
            currentState: "Current psychophysical state",
            criticalEvents: "Critical events",
          },
          questionExtendedTexts: {
            commonText: "Describe the circumstances of this event/situation",
          },
          questionTexts: {
            question1: "Has the subject consumed alcohol in the last 48 hours?",
            question2: "Has the subject worked professionally during the last or previous night?",
            question3: "Number of working hours in the last 24 hours",
            question4: "Head injury requiring medical attention",
            question5: "Loss of consciousness",
            question6: "Hospital stay",
            question7: "Has there ever been or is there currently treatment for/has there ever been a diagnosis of the following conditions:",
            question7a: "a. digestive system diseases (gastric or duodenal ulcer, intestinal dysfunction, etc.)",
            question7b: "b. heart and circulatory system diseases (coronary disease, high blood pressure)",
            question7c: "c. hearing or vision disorders (excluding corrective glasses)",
            question7d: "d. diabetes",
            question7e: "e. epilepsy",
            question7f: "f. mental illnesses (depression, neurosis, psychiatric treatment (inquire about psychiatric treatment of a close family member)",
            question8: "Regular medication use (inquire if aware of side effects and ask about other medications affecting mental functions)",
            question9: "Sleep problems",
            question10: "Use of assistance from:",
            question10a: "a. psychologist",
            question10b: "b. psychiatrist",
            question10c: "c. neurologist",
            question11: "Disability certificate",
            question12: "Problems due to alcohol consumption",
            question13: "Stay in sobering-up station",
            question14: "Use of intoxicants/psychoactive substances/drugs",
            question15: "Suggestions from a doctor or someone close to seek treatment due to nervousness, absent-mindedness, or problems with memory or orientation",
          }
        },
        socialAndFamilySituation: {
          questionTexts: {
            question1: "Serious work-related conflict or problem " +
              "(e.g., workplace accident, reprimand, labor court, disciplinary dismissal, mobbing)",
            question2: "Problems during education " +
              "(e.g., repeating a grade, expulsion from school, suspension of student rights)",
            question3: "Serious family conflict or problems in private situations " +
              "(e.g., court cases, involvement in fights, may ask about relationship status, children)",
            question4: "Direct life-threatening situation (e.g., fire, flood, and other disasters)",
            question5: "Experience of physical violence against oneself (e.g., assault, theft, battery)",
            question6: "Involvement in social organizations (e.g., volunteering)",
            question7: "Criminal record (e.g., receiving a fine, court sentence)"
          }
        },
        observation: {
          questionTexts: {
            question1: "Contact with interviewer",
            question2: "Language and rhetoric",
            question3: "Mood and attitude",
            question4: "Composure and control of behavior and emotions",
          },
          questionAnswers: {
            question1a: "Establishes very good contact with the interviewer; maintains eye contact; " +
              "behaves confidently and naturally, doesn't allow themselves to be thrown off balance or become impatient, " +
              "behaves spontaneously and naturally.",
            question1b: "Establishes contact with the interviewer; maintains eye contact; behaves naturally, " +
              "although signs of some nervousness are visible.",
            question1c: "Behaves in a distant manner, avoids eye contact; behaves unnaturally, " +
              "small stimuli cause loss of balance, clear signs of nervousness and impatience are visible.",
            question2a: "Formulates statements using correct language, " +
              "constructs clear, readable sentences, maintains logical structure of speech, " +
              "uses rich vocabulary.",
            question2b: "Formulates statements correctly, " +
              "generally maintains their logical structure; average but correct vocabulary.",
            question2c: "Formulates short, perfunctory statements, often incoherent or unclear; " +
              "uses incorrect phrases and formulations; sentences are very simple, " +
              "vocabulary is very poor, grammatical errors occur.",
            question3a: "Observable very cheerful, optimistic mood; satisfaction with self and life situation.",
            question3b: "Observable moderate mood, fairly positive attitude and generally optimistic outlook for the future.",
            question3c: "Observable disproportionately strong emotional reactions, " +
              "clear tendency towards pessimistic perception of the world; showing dissatisfaction.",
            question4a: "During examination adequately controls behavior and emotions, " +
              "adequately adapts to emerging circumstances.",
            question4b: "During examination generally controls behavior and emotions; " +
              "most often responds adequately to emerging circumstances.",
            question4c: "During examination observable difficulties with controlling behaviors and emotions; " +
              "inadequate behaviors or emotions appear.",
          },
        },
        drivingVehicles: {
          headers: {
            drivingCharacteristics: "Route characteristics",
          },
          questionTexts: {
            question1: "Overall driving experience",
            question2: "Professional driving experience",
            question3: "Annual mileage",
            question4: "Hours spent driving",
            question5: "Route length",
            question6: "Route variety",
            question7: "Time of day",
            question8: "Relief driver",
            question9: "Area",
            question10: "Driver's license suspension or revocation",
            question11: "Declaration of inability to drive",
            question12: "Penalty for traffic offense or violation",
            question13: "Involvement as driver in car accident",
            question14: "Involvement as driver in traffic collision",
            question15: "Additional qualifications and licenses",
          },
          questionAnswers: {
            question3a: "less than 3,000 km annually",
            question3b: "between 3,000 – 15,000 km annually",
            question3c: "between 15,000 - 70,000 km annually",
            question3d: "above 70,000 km annually",
            question3e: "difficult to say",

            question4a: "less than 5 hours weekly",
            question4b: "between 5 - 15 hours weekly",
            question4c: "between 15 - 40 hours weekly",
            question4d: "above 40 hours weekly",
            question4e: "difficult to say",

            question5a: "I mostly drive short routes",
            question5b: "I mostly drive long routes",
            question5c: "I drive both long and short routes",

            question6a: "I usually drive fixed routes",
            question6b: "I usually drive different routes",

            question7a: "I most often drive at night",
            question7b: "I usually drive during the day",
            question7c: "I drive both during day and night",

            question8a: "I mainly drive alone without relief",
            question8b: "I most often drive with relief",

            question9a: "I mainly drive in the city",
            question9b: "I mainly drive outside the city",

            question13a: "as perpetrator/co-perpetrator",
            question13b: "as victim",
            question13c: "not applicable",

            question14a: "as perpetrator/co-perpetrator",
            question14b: "as victim",
            question14c: "not applicable",

            question15a: "hazardous materials transport",
            question15b: "passenger transport",
            question15c: "driving instructor",
            question15d: "driving examiner",
            question15e: "none",
          },
        },
        occupationalMedicine: {
          headers: {
            workInformation: "Professional work information",
            workCourseInformation: "Professional career history",
            companyCarDrivers: "Company car drivers",
            workingAtHeights: "Working at heights",
            forkliftOperators: "Forklift operators",
            minersAndMinerLifeguards: "Miners and mine rescuers",
            trainDriver: "Train drivers",
          },
          questionTexts: {
            question1: "Trained profession",
            question2: "Current profession",
            question3: "Workplace / school",
            question4: "Position / performed duties",
            question5: "Permanent harmful/burdensome factors in the workplace",
            question5f: "Which ones?",
            question5g: "Which ones?",
            question6: "Total work experience",
            question7: "Experience in current position",
            question8: "Occupational disease diagnosed",
            question8a: "When? For what reason?",
            question9: "Disability benefits granted",
            question9a: "For what reason? Since when? Until when?",
            question10: "Subject involved in workplace accident",
            question10a: "When? What were the health consequences?",
            question11: "Disability determined",
            question11a: "For what reason? Since when? Until when?",
            question12: "Overall driving experience",
            question13: "Annual mileage",
            question14: "Hours spent driving",
            question15: "Declaration of inability to drive",
            question16: "Driver's license suspension or revocation",
            question17: "Penalty for traffic offense or violation",
            question18: "Involvement as driver in car accident",
            question19: "Involvement as driver in traffic collision",
            question20: "Qualifications for working at heights",
            question21: "Performance of work at heights (if yes, ask how many meters above ground)",
            question22: "Subjective feeling of fear while at heights",
            question23: "Accident while working at heights",
            question23a: "Was there damage to property or health?",
            question24: "Qualifications for forklift operation",
            question25: "Performance of work related to forklift operation and driving",
            question26: "Accident during forklift operation or driving",
            question26a: "Was there damage to property or health?",
          },
          questionAnswers: {
            question5a: "Monotonous work",
            question5b: "Constant high information input",
            question5c: "High noise level",
            question5d: "Work with high psychological load (responsibility and decision-making)",
            question5e: "Ionizing radiation",
            question5f: "Chemical factors",
            question5g: "Other",

            question13a: "less than 3,000 km annually",
            question13b: "between 3,000 – 15,000 km annually",
            question13c: "between 15,000 - 70,000 km annually",
            question13d: "above 70,000 km annually",
            question13e: "difficult to say",

            question14a: "less than 5 hours weekly",
            question14b: "between 5 - 15 hours weekly",
            question14c: "between 15 - 40 hours weekly",
            question14d: "above 40 hours weekly",
            question14e: "difficult to say",

            question18a: "as perpetrator/co-perpetrator",
            question18b: "as victim",
            question18c: "not applicable",

            question19a: "as perpetrator/co-perpetrator",
            question19b: "as victim",
            question19c: "not applicable",
          },
        },
        licensedActivity: {
          questionTexts: {
            question1: "Total work experience in a facility engaged in licensed activities",
            question2: "Position/performed duties",
            question3: "Work experience in current position in a facility engaged in licensed activities",
            question4a: "Involvement in workplace accident",
            question4b: "What were the causes, consequences of the accident - was there damage to property, health?",
          },
        },
        detectivesSecurityGuardsAndWeaponPermits: {
          questionTexts: {
            question1: "Primary purposes of weapon use:",
            question1g: "Which ones?",
            question2: "Has there ever been any use of weapons or other means of direct coercion?",
            question3: "How frequently have weapons been used in the last 5 years?",
          },
          questionAnswers: {
            question1a: "for professional purposes (detective, security guard)",
            question1b: "for hunting purposes",
            question1c: "for sports purposes",
            question1d: "for personal use",
            question1e: "for museum, commemorative, scientific purposes",
            question1f: "other",
          },
        },
      },
    },
  },
  Language: { pl: "PL", ru: "RU", uk: "UK" },
  SearchEngine: {
    header: "Search examinees",
    placeholderMobile: "Search",
    placeholderDesktop: "First name, last name, PESEL/ID, examination assignment date (YYYY-MM-DD)",
    noResults: "No search results",
    noResultsForFilter: "No examinees found matching the entered requirements.",
    searchForExaminees: "Search examinees",
    enterFilter: "You can search by first name, last name, PESEL/ID number\n" +
      "or by examination assignment date \n" +
      "in YYYY-MM-DD format.",
  },
  AdminSearchEngine: {
    header: "Search clients",
    placeholderMobile: "Search",
    placeholderDesktop: "name, ID, address, tax ID number, creation date, client account expiry date in YYYY-MM-DD format, user's first name, last name, email, license expiry date, methodology, test",
    noResults: "No search results",
    noResultsForFilter: "No clients found matching the entered requirements.",
    searchForExaminees: "Search clients",
    enterFilter: "You can search by name, ID, address, tax ID number, creation date, client account expiry date in YYYY-MM-DD format, user's first name, last name, email, license expiry date in YYYY-MM-DD format, methodology, test.",
  },
  Snackbars: {
    createExamineeSuccess: "Examinee added successfully",
    editExamineeSuccess: "Examinee edited successfully",
    createPsychLabSuccess: "Client account added successfully",
    editPsychLabSuccess: "Client account edited successfully",
    failedRequest: "Problem occurred: ",
    movingExamineesSuccess: "Successfully moved to another folder",
    passwordChangeSuccess: "Password change successful",
    pinChangeSuccess: "PIN change successful",
    success: "Action successful",
    unrecognizedError: "Unrecognized error, check internet connection",
    createExaminationSuccess: "Examination assigned correctly",
    deleteExaminationSuccess: "Examination deleted correctly",
    editPsychUserSuccess: "User edited successfully",
    createPsychUserSuccess: "User added successfully",
    createPsychLicSuccess: "License added successfully",
    editPsychLicSuccess: "License edited successfully",
  },
  UserSettings: {
    header: "Account settings",
    headerChangePassword: "Change password",
    headerChangePin: "Change PIN",
    setNewPin: "Set new PIN",
    pinInfo: "Enter the PIN number that you will use to log in to the administrator panel.",
    headerMobile: "Settings",
    passwordChange: "Password Change",
    pinChange: "PIN Change",
    twoFactorAuth: "Two-Factor Authentication",
    passwordExpiry: "Password expiry date:",
    facilityInfo: "Facility Information",
    userInfo: "User Information",
    helpdeskMsgUser: "If you have any questions or problems, please contact our technical support:",
    infoSubHeaders: {
      labName: "Name",
      address: "Address",
      cin: "Registration number",
      tin: "Tax ID",
      dateExpire: "Account expiry date",
      firstName: "First name",
      lastName: "Last name",
      email: "Email"
    }
  },
  TwoFactor: {
    setupTitle: "Set up Two-Factor Authentication",
    verifyTitle: "Verify Two-Factor Authentication",
    disableTitle: "Disable Two-Factor Authentication",
    scanQRCode: "Scan this QR code with your authenticator app",
    secretKey: "Secret key",
    backupCodes: "Backup Codes",
    backupCodesInfo: "Save these backup codes in a safe place. You can use them to access your account if you lose your authenticator device.",
    enterCode: "Enter the verification code from your authenticator app",
    verificationCode: "Verification code",
    confirmDisable: "Enter your password to disable two-factor authentication",
    setupError: "Failed to set up two-factor authentication",
    verificationError: "Invalid verification code",
    disableError: "Failed to disable two-factor authentication",
    setupSuccess: "Two-factor authentication has been successfully set up",
    disableSuccess: "Two-factor authentication has been successfully disabled",
    useBackupCode: "Use backup code",
    useAuthenticator: "Use authenticator",
    enterBackupCode: "Enter backup code",
    backupCode: "Backup code"
  },
  Backend: {
    User_activation_code_has_already_been_used:
      "The activation code has already been used.",
    User_activation_code_does_not_exist: "The provided activation code does not exist.",
    User_with_given_id_does_not_exist: "The user with the given id does not exist.",
    Token_not_recognized: "The token was not recognized.",
    Unrecognized_token_Please_regenerate_password_reset_link:
      "Unrecognized token, please generate a new password reset link.",
    Invalid_token_Please_regenerate_password_reset_link:
      "Invalid token, please generate a new password reset link.",
    Validation_failed: "Validation of entered data failed.",
    Could_not_bind_users_PsychologicalLab_with_the_examinee:
      "It was not possible to link the user's facility with the participant.",
    Could_not_bind_users_PsychologicalLab_with_the_directory:
      "It was not possible to link the user's facility with the folder.",
    The_password_cannot_be_the_same_as_the_previous_one:
      "The password cannot be the same as the previous one.",
    Incorrect_credentials: "Invalid password",
    Unrecognized_email:
      "The email address was not recognized or the account was not activated.",
    Directory_has_related_Examinees:
      "Unable to delete Folder Folder has participants associated with it.",
    Unable_to_delete_default_directory:
      "It is not possible to delete the Default folder.",
    The_new_pin_cannot_be_the_same_as_the_previous_one:
      "The new PIN cannot be the same as the previous one.",
    Directory_with_given_name_already_exists:
      "A folder with this name already exists.",
    Examination_Card_does_not_have_a_defined_NormSet:
      "The examination card does not have an assigned norm.",
    Examinee_with_the_given_id_exists_in_this_psychological_lab:
      "A participant with the given ID already exists.",
    The_Examinee_has_related_Examinations:
      "Unable to delete Participant Participant has assessments associated with it.",
    Unrecognized_proxy_error_occurred_while_fetching_legacy_data:
      "Unrecognized error downloading archival assessments.",
    Legacy_data_instance_not_found: "No archived data found.",
    Legacy_data_validation_failed: "Validation error: archive data.",
    Unable_to_parse_Examination_Aim: "Validation error: Purpose of assessment.",
    Unable_to_parse_Occupational_Medicine_Basic_Info_data:
      "Validation error: Assessment - Occupational Medicine.",
    Ongoing_examination: "It is not possible to delete the assessment. Assessment in progress.",
  },
  Onboarding: {
    errorText:
      "An error occurred while setting your password, please try again later.",

    successTitle: "The password and PIN have been set",
    success:
      "Your password and PIN have been saved. You can now log in to the application for the first time using your email address and password.",
    setNewPasswordInfo:
      "Enter the password you will use to log in to the system.",
    setPasswordFormLabel: "Set a password",
    setPinInfo:
      "Enter the PIN number you will use to log in to the administrator panel.",
    setPinFormLabel: "Set PIN",
  },
  ResetPassword: {
    formHeader: "Reset password",
    resetInfo:
      "Enter your account email address to receive a password reset link.",
    resetEmailSentPt1: "E-mail adress:",
    resetEmailSentPt2:
      "to which a message was sent containing instructions on the password reset process.",
    errorText:
      "An error occurred while resetting your password, please try again later.",
    successTitle: "Password was saved",
    success:
      "Your password has been changed. You can now log in to the application using your email address and new password.",
    setNewPassword: "Set a new password",
    setNewPasswordInfo:
      "Enter the password you will use to log in to the system.",
  },
  FormLabels: {
    email: "Email",
    password: "Password",
    currentPassword: "Current password",
    newPassword: "New password",
    pin: "PIN",
  },
  FormValidationErrors: {
    required: "This field is required",
    email: "The entered value is not an email address",
  },
  Login: {
    header: "LOGIN",
    adminHeader: "ADMIN PANEL",
    error: "Incorrect email or password.",
    warning: "CAPS LOCK is on",
    reset: "Reset password",
    Instance_configured_incorrectly: "System configuration error. Report the issue to technical support.",
    No_recaptcha_token_provided: "Recaptcha error. Report the issue to technical support.",
    Validation_failed: "Incorrect email or password.",
    Laboratory_is_inactive_or_deleted: "Client account is inactive",
    User_account_is_not_active: "User account has been deleted",
    User_account_has_been_deleted: "User account is inactive",
    Captcha_error: "Error completing captcha. Please try again.",
    Access_denied: "Access denied"
  },
  Directory: {
    defaultFolderName: "Default folder",
    header: "Examinee Records",
    popup: {
      create: "Enter folder name",
      edit: "Change folder name",
    },
    emptyState: {
      header: "No examinees in selected folder",
      info: "To see the list of examinees in the folder, add at least one examinee",
    },
    labels: {
      name: "Folder name",
      owner: "Owner",
      examineeCount: "People",
    },
    kebabMenu: {
      addExaminee: "Add examinee",
      addDirectory: "Add folder",
      edit: "Edit folder name",
      delete: "Delete folder",
    },
    popups: {
      delete: {
        confirmationHeader: "Are you sure you want to delete the folder?",
        confirmationText: "Once deleted, the folder cannot be restored.",
      },
    },
  },
  DirectoryPreview: {
    headerPopup: "Move examinees",
    labels: {
      firstName: "First name",
      lastName: "Last name",
      peselSlashIdentifier: "ID Number / Identifier",
      lastModification: "Last activity",
    },
    kebabMenu: {
      edit: "Edit examinee data",
      move: "Move examinee",
      delete: "Delete examinee",
    },
    breadcrumbs: "Examinee records",
  },
  Pagination: {
    outOf: "of",
  },
  PasswordExpired: {
    popup: {
      header: "Password has expired",
      text: "You will be redirected to the password reset process to set a new password.",
    },
    form: {
      header: "Set new password",
      text: "Enter the password you will use to log in to the system.",
    },
    success: {
      header: "Password saved",
      text: "Your password has been changed. You can now proceed to the application.",
    },
  },
  DocumentationManagement: {
    snackbar: {
      save: "Document saved successfully",
    },
    names: {
      ExaminationCard: "Examination Card",
      ExaminationCardTEST2WORK: "Examination Card TEST2WORK",
      ExaminationCardTEST2SAFE: "Examination Card TEST2SAFE",
      ExaminationCardTEST2ARMS: "Examination Card TEST2ARMS",
      ExaminationCardTEST2DRIVE: "Examination Card TEST2DRIVE",
      PsychologicalConsultation: "Psychological Consultation",
      PsychologicalConsultationTEST2WORK: "Psychological Consultation",
      PsychologicalConsultationTEST2SAFE: "Psychological Consultation",
      PsychologicalConsultationTEST2ARMS: "Psychological Consultation",
      PsychologicalConsultationTEST2DRIVE: "Psychological Consultation",
      PsychologicalOpinion: "Psychological Opinion",
      PsychologicalOpinionTEST2WORK: "Psychological Opinion",
      PsychologicalOpinionTEST2SAFE: "Psychological Opinion - Detectives",
      PsychologicalOpinionTEST2ARMS: "Psychological Opinion",
      PsychologicalOpinionTEST2DRIVE: "Psychological Opinion",
      DriverJudgment: "Driver Assessment",
      DriverJudgmentTEST2WORK: "Driver Assessment",
      DriverJudgmentTEST2SAFE: "Driver Assessment",
      DriverJudgmentTEST2ARMS: "Driver Assessment",
      DriverJudgmentTEST2DRIVE: "Driver Assessment",
      DrivingExaminerJudgment: "Driving Examiner Assessment",
      DrivingExaminerJudgmentTEST2WORK: "Driving Examiner Assessment",
      DrivingExaminerJudgmentTEST2SAFE: "Driving Examiner Assessment",
      DrivingExaminerJudgmentTEST2ARMS: "Driving Examiner Assessment",
      DrivingExaminerJudgmentTEST2DRIVE: "Driving Examiner Assessment",
      DrivingInstructorJudgment: "Driving Instructor Assessment",
      DrivingInstructorJudgmentTEST2WORK: "Driving Instructor Assessment",
      DrivingInstructorJudgmentTEST2SAFE: "Driving Instructor Assessment",
      DrivingInstructorJudgmentTEST2ARMS: "Driving Instructor Assessment",
      DrivingInstructorJudgmentTEST2DRIVE: "Driving Instructor Assessment",
      DrivingTechniqueInstructorJudgment: "Driving Technique Instructor Assessment",
      EmergencyVehicleDriverJudgment: "Emergency Vehicle Driver Assessment",
      EmergencyVehicleDriverJudgmentTEST2WORK: "Emergency Vehicle Driver Assessment",
      EmergencyVehicleDriverJudgmentTEST2SAFE: "Emergency Vehicle Driver Assessment",
      EmergencyVehicleDriverJudgmentTEST2ARMS: "Emergency Vehicle Driver Assessment",
      EmergencyVehicleDriverJudgmentTEST2DRIVE: "Emergency Vehicle Driver Assessment",
      FirearmLicenseJudgment: "Firearm License Assessment",
      FirearmLicenseJudgmentTEST2WORK: "Firearm License Assessment",
      FirearmLicenseJudgmentTEST2SAFE: "Firearm License Assessment",
      FirearmLicenseJudgmentTEST2ARMS: "Firearm License Assessment",
      FirearmLicenseJudgmentTEST2DRIVE: "Firearm License Assessment",
      MilitaryExplosivesJudgment: "Police/Military Weapons and Explosives Assessment",
      MilitaryExplosivesJudgmentTEST2WORK: "Police/Military Weapons and Explosives Assessment",
      MilitaryExplosivesJudgmentTEST2SAFE: "Police/Military Weapons and Explosives Assessment",
      MilitaryExplosivesJudgmentTEST2ARMS: "Police/Military Weapons and Explosives Assessment",
      MilitaryExplosivesJudgmentTEST2DRIVE: "Police/Military Weapons and Explosives Assessment",
      OccupationalMedicineJudgmentTEST2WORK: "Occupational Medicine Assessment",
      OccupationalMedicineJudgmentTEST2SAFE: "Occupational Medicine Assessment",
      OccupationalMedicineJudgmentTEST2ARMS: "Occupational Medicine Assessment",
      OccupationalMedicineJudgmentTEST2DRIVE: "Occupational Medicine Assessment",
      PhysicalProtectionJudgment: "Security Guard License Assessment",
      PhysicalProtectionJudgmentTEST2WORK: "Security Guard License Assessment",
      PhysicalProtectionJudgmentTEST2SAFE: "Physical Security Officer Assessment",
      PhysicalProtectionJudgmentTEST2ARMS: "Security Guard License Assessment",
      PhysicalProtectionJudgmentTEST2DRIVE: "Security Guard License Assessment",
      ProfessionalDriverJudgment: "Professional Driver Position Assessment",
      ProfessionalDriverJudgmentTEST2WORK: "Professional Driver Position Assessment",
      ProfessionalDriverJudgmentTEST2SAFE: "Professional Driver Position Assessment",
      ProfessionalDriverJudgmentTEST2ARMS: "Professional Driver Position Assessment",
      ProfessionalDriverJudgmentTEST2DRIVE: "Professional Driver Position Assessment"
    },
    emptyState: {
      header: "Examination has not been completed",
    },
    report: {
      details: {
        assignDate: "Examination assignment date",
        endDate: "Examination completion date",
        firstName: "First name",
        PESEL: "ID Number",
        identificationNumber: "Identifier",
        idExamination: "Examination ID",
        lastName: "Last name",
        name: "Examination name",
        status: "Examination status",
      },
      statusExamination: {
        skip: "Some tests were skipped or interrupted",
      },
      header: {
        language: "Select report language",
        standard: "Select standard",
        recipient: "Select recipient",
        interval: "Select confidence interval",
        reportInterpretation: "Interpretative report",
        reportDiagnostic: "Diagnostic report",
      },
    },
    consultation: {
      breadcrumbs: "Psychological Consultation",
      examineeData: "Examinee Data",
      statement: "I hereby state",
    },
    opinion: {
      breadcrumbs: "Psychological Opinion",
      breadcrumbsAppeal: "Appeal Psychological Opinion",
      examineeData: "Examinee Data",
      statement: "BASED ON THE CONDUCTED PSYCHOLOGICAL EXAMINATION, I HEREBY STATE",
    },
    judgment: {
      isAppealTitle: "APPEAL ASSESSMENT",
      appeal: "This assessment may be appealed to",
      appealDate: "Within",
      appealDay: "Within",
      appealDay2: "days from the date of receipt",
      appealDayTlt: "Number of days",
      drivingLicense: "Regarding driving license category",
      judgmentNumber: "Psychological assessment No.",
      judgmentNumberAppeal: "Psychological assessment No.",
      legalBais: "Legal basis for examination",
      statement: "I hereby state*",
      occupationalMedicineStatement: "I hereby declare*",
      statementThat: "I hereby state that",
      nextLegalBasis: "Legal basis for next examination:",
      remarks: "Remarks",
      ART_1: "Article 39k paragraph 3 point 1",
      ART_2: "Article 39k paragraph 3 point 2",
      examiner_ART_1: "Article 34 paragraph 5 point 1",
      examiner_ART_2: "Article 34 paragraph 5 point 2",
      header: {
        base: "Assessment",
        baseAppeal: "Appeal Assessment",
        baseAppealFooter: "This assessment is final.",
        civilExplosives: "Licensed Activity - for Civil Use",
        civilExplosivesAppeal: "Licensed Activity - for Civil Use - Appeal",
        driver: "Vehicle Operation",
        emergencyVehicleDriver: "Emergency Vehicle Driver",
        firearmLicense: "Firearm License",
        militaryExplosives: "Licensed Activity - for Military or Police Use",
        physicalProtection: "Security Officer",
        profesionalDriver: "Professional Driver Position",
        drivingInstructor: "Instructor",
        drivingExaminer: "Examiner",
        occupationalMedicine: "Occupational Medicine"
      },
      emergencyVehicleDriver: {
        true: "no contraindications for operating emergency vehicles or cash-in-transit vehicles",
        false: "contraindications exist for operating emergency vehicles or cash-in-transit vehicles"
      },
      drivingVehicles: {
        true: "no psychological contraindications for operating vehicles",
        false: "psychological contraindications exist for operating vehicles"
      },
      physicalProtection: {
        true: "is capable of performing physical security officer duties",
        false: "is not capable of performing physical security officer duties"
      },
      civilExplosives: {
        true: "shows no psychological dysfunction and therefore may engage in activities related to acquisition and storage of explosives intended for civil use",
        false: "shows psychological dysfunction and therefore may not engage in activities related to acquisition and storage of explosives intended for civil use"
      },
      drivingInstructor: {
        true: "no contraindications for performing duties of instructor/examiner/driving technique instructor",
        false: "contraindications exist for performing duties of instructor/examiner/driving technique instructor"
      },
      drivingExaminer: {
        true: "no contraindications for performing duties of instructor/examiner/driving technique instructor",
        false: "contraindications exist for performing duties of instructor/examiner/driving technique instructor"
      },
      militaryExplosives: {
        true: "shows no significant psychological dysfunction that would prevent conducting or managing business activities in manufacturing and trading of explosives, weapons, ammunition, and products and technology for military or police use",
        false: "shows significant psychological dysfunction that prevents conducting or managing business activities in manufacturing and trading of explosives, weapons, ammunition, and products and technology for military or police use"
      },
      professionalDriver: {
        true: "no psychological contraindications for working in the position of driver",
        false: "psychological contraindications exist for working in the position of driver"
      },
      firearmLicense: {
        true: "does not belong to persons mentioned in Art. 15 par. 1 point 3 of the Act of May 21, 1999 on weapons and ammunition and may possess firearms",
        false: "belongs to persons mentioned in Art. 15 par. 1 point 3 of the Act of May 21, 1999 on weapons and ammunition and may not possess firearms"
      },
      occupationalMedicine: {
        true: "no psychological contraindications for",
        false: "psychological contraindications exist for",
        justification: "Justification"
      },
    },
    ExaminationCard: {
      header: "Examination Card",
      headerTEST2WORK: "Examination Card TEST2WORK",
      headerTEST2SAFE: "Examination Card TEST2SAFE",
      headerTEST2ARMS: "Examination Card TEST2ARMS",
      headerTEST2DRIVE: "Examination Card TEST2DRIVE",
      fetchUpdated: "Update with interview data and results",
      tooltipFetchUpdated: "This action will delete all unsaved changes from the Examination Course",
      tooltipFetchUpdatedDisabled: "To unlock this action, select examination standard.",
      tabs: {
        examinee: "Examinee",
        courseOfExamination: "Course of Examination",
        results: "Psychological Examination Results"
      },
      examinee: {
        chosenNormSet: "Standard applied in examination",
        examinationDate: "Examination Date",
        previousExaminationDate: "Previous examination date",
        aim: "Purpose of examination",
        entityDirectingToExamination: "Entity referring for examination",
        t2dAim: {
          drivingVehiclesAim: "Determination of absence or presence of contraindications for operating vehicles.",
          professionalDriverAim: "Determination of absence or presence of contraindications for working in the position of driver.",
          emergencyDriverAim: "Determination of absence or presence of contraindications for operating emergency vehicles or cash-in-transit vehicles.",
          drivingInstructorAim: "Determination of absence or presence of contraindications for performing duties of instructor/examiner/driving technique instructor."
        }
      },
      examinationCourse: {
        forcedUpdateHeader: "Update Examination Card with Interview Data and Results",
        forcedUpdateBody: "To unlock the form, complete the Examination Interview, select a standard in the Examinee tab and update the Examination Card with Interview Data and Results.",
        header: "Data from direct interview and observation of the examinee",
        methodology: "Examination methods and results",
        recommendations: "Recommendations",
        conclusions: "Conclusions",
        conclusionsRecomendation: "Conclusions and recommendations",
        careerHistory: "Work history",
        examinationResultsHeader: "Test name and results (within recommended methodology)",
        examinationExtendedResultsHeader: "Test name and additional results",
        cognitiveResultsHeader: "Assessment and description of examinee's intellectual capacity and cognitive processes (within recommended methodology)",
        cognitiveExtendedResultsHeader: "Assessment and description of examinee's intellectual capacity and cognitive processes - additional results",
        personalityResultsHeader: "Assessment and description of examinee's personality, including functioning in difficult situations and social maturity (within recommended methodology)",
        personalityExtendedResultsHeader: "Assessment and description of examinee's personality, including functioning in difficult situations and social maturity - additional results",
        psychomotorResultsHeader: "Psychomotor performance (within recommended methodology)",
        psychomotorExtendedResultsHeader: "Psychomotor performance - additional results",
        occupationalMedicineAdditionalInfo: "Additional information - Occupational Medicine",
        examinationResultsT2WHeader: "Recommended methodology",
        examinationExtendedResultsT2WHeader: "Additional",
        addExaminationExtendedResults: "Add additional result",
        deletePopup: {
          headerText: "Are you sure you want to delete this result?",
          contentText: "After deleting the result, you can restore its original content by updating the data",
          confirmationText: "Yes, I want to delete the result"
        }
      },
      judgment: {
        result: "Psychological examination result",
        sent: "Assessment/copy of assessment sent, if applicable",
        judgment: "Assessment content",
        justification: "Assessment justification",
        justificationText: "Justification content",
        recommendations: "Recommendations",
        to: "to",
        t2d: {
          lack: "none",
          existing: "exists",
          questions: {
            drivingVehiclesContraindication: "Contraindications for operating vehicles.",
            professionalDriverContraindication: "Contraindications for working in the position of driver.",
            emergencyVehicleDriverContraindication: "Contraindications for operating emergency vehicles or cash-in-transit vehicles.",
            drivingInstructorContraindication: "Contraindications for performing duties of instructor/examiner/driving technique instructor."
          }
        }
      },
    },
  },
};
