import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  AsyncThunkConfig,
  isAxiosError,
  ApiError,
  ApiResponse,
} from "../../api/axiosConfig";
import { loginAdminURL, loginURL, loginVerifyURL, logoutURL, updateAdminLabURL } from "../../utils/auth";
import { UpdateAdminLabIdApi, User } from "../../api/userApi";
import { dispatchNotification } from "../../utils/redux";
import {
  getResetPasswordEmailURL,
  resetPasswordSendRequestURL,
} from "../../utils/paths";
import { Nullable } from "../../utils/types";
import store from "../store";
import { clearAuthState } from "../reducers/authReducers";
import { I18n } from "react-redux-i18n";
import {
  Setup2FAResponse,
  Setup2FAApiResponse,
  Verify2FAArgs,
  Verify2FASetupArgs,
  Disable2FAArgs,
  Verify2FALoginArgs
} from '../../api/twoFactorTypes';
import { API_PATHS } from '../../utils/paths';

export const logout = createAsyncThunk<undefined, undefined, AsyncThunkConfig>(
  "user/logout",
  async (arg_, thunkAPI) => {
    try {
      await client.post(logoutURL);
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        return thunkAPI.rejectWithValue(err.response.data as ApiError);
      }

      throw err;
    }
  }
);

export const logoutAdmin = createAsyncThunk<undefined, undefined, AsyncThunkConfig>(
  "user/logout-admin",
  async (arg_, thunkAPI) => {
    try {
      await client.post(logoutURL);
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        return thunkAPI.rejectWithValue(err.response.data as ApiError);
      }

      throw err;
    }
  }
);

type getResetPasswordEmailArgs = {
  email: string;
};

export const getResetPasswordEmail = createAsyncThunk<undefined,
  getResetPasswordEmailArgs,
  AsyncThunkConfig>("getResetPasswordEmail", async (data, thunkAPI) => {
    try {
      await client.post(getResetPasswordEmailURL, data);
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        const extractedError = err.response.data as ApiError;

        dispatchNotification("error", extractedError.message, true);

        return thunkAPI.rejectWithValue(extractedError);
      }

      dispatchNotification("error");
      throw err;
    }
  });

export const resetPasswordResetSuccess = createAction(
  "resetPasswordResetSuccess"
);

type resetPasswordArgs = {
  userId: string | null;
  token: string;
  newPassword: string;
};

export const resetPasswordSendRequest = createAsyncThunk<undefined,
  resetPasswordArgs,
  AsyncThunkConfig>("resetPasswordSendRequest", async (data, thunkAPI) => {
    try {
      await client.post(resetPasswordSendRequestURL, data);
    } catch (err) {
      if (isAxiosError(err) && err.response != null) {
        const extractedError = err.response.data as ApiError;

        dispatchNotification("error", extractedError.message, true);

        return thunkAPI.rejectWithValue(extractedError);
      }
      dispatchNotification("error");
      throw err;
    }
  });

type loginArgs = {
  email: string;
  password: string;
  captchaToken?: Nullable<string>;
  token?: string;
  backupCode?: string;
};

type loginResponse = {
  user: User;
  theme: string;
  need2fa?: boolean;
  tempToken?: string;
};

export const login = createAsyncThunk<loginResponse,
  loginArgs,
  AsyncThunkConfig>("login", async (data, thunkAPI) => {
    try {
      const response = await client.post<ApiResponse<loginResponse>>(
        loginURL,
        data
      );

      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        const extractedError = error.response.data as ApiError;

        return thunkAPI.rejectWithValue(extractedError);
      }
      dispatchNotification("error");
      throw error;
    }
  });

export const loginAdmin = createAsyncThunk<loginResponse,
  loginArgs,
  AsyncThunkConfig>("loginAdmin", async (data, thunkAPI) => {
    try {
      const response = await client.post<ApiResponse<loginResponse>>(
        loginAdminURL,
        data
      );

      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        const extractedError = error.response.data as ApiError;

        return thunkAPI.rejectWithValue(extractedError);
      }
      dispatchNotification("error");
      throw error;
    }
  });

export const verifyLogin = createAsyncThunk<User, void, AsyncThunkConfig>(
  "loginVerify",
  async (data, thunkAPI) => {
    try {
      const response = await client.get<ApiResponse<User>>(loginVerifyURL);

      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        return thunkAPI.rejectWithValue(error.response.data as ApiError);
      }
      throw error;
    }
  }
);

export const updateAdminLab = createAsyncThunk<boolean, UpdateAdminLabIdApi, AsyncThunkConfig>(
  "updateAdminLabId",
  async (data, thunkAPI) => {
    try {
      const response = await client.post<ApiResponse<boolean>>(updateAdminLabURL, data);

      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        return thunkAPI.rejectWithValue(error.response.data as ApiError);
      }
      throw error;
    }
  }
);

export const clearAuthenticationState = () => {
  const { dispatch } = store;

  dispatch(clearAuthState());
};

export const setup2FA = createAsyncThunk<Setup2FAResponse, void, AsyncThunkConfig>(
  "setup2FA",
  async (_, thunkAPI) => {
    try {
      const response = await client.post<ApiResponse<Setup2FAApiResponse>>(API_PATHS.TWO_FACTOR.SETUP);
      if (!response.data?.results) {
        throw new Error('Invalid response structure - missing results');
      }
      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        const extractedError = error.response.data as ApiError;
        return thunkAPI.rejectWithValue(extractedError);
      }
      throw error;
    }
  }
);

export const verify2FASetup = createAsyncThunk<User, Verify2FASetupArgs, AsyncThunkConfig>(
  "verify2FASetup",
  async (data, thunkAPI) => {
    try {
      await client.post(API_PATHS.TWO_FACTOR.VERIFY_SETUP, data);
      const response = await client.get<ApiResponse<User>>(loginVerifyURL);
      dispatchNotification("success", I18n.t("TwoFactor.setupSuccess"));
      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        const extractedError = error.response.data as ApiError;
        dispatchNotification("error", I18n.t("TwoFactor.verificationError"));
        return thunkAPI.rejectWithValue(extractedError);
      }
      dispatchNotification("error", I18n.t("TwoFactor.verificationError"));
      throw error;
    }
  }
);

export const disable2FA = createAsyncThunk<User, Disable2FAArgs, AsyncThunkConfig>(
  "disable2FA",
  async (data, thunkAPI) => {
    try {
      await client.post(API_PATHS.TWO_FACTOR.DISABLE, data);
      const response = await client.get<ApiResponse<User>>(loginVerifyURL);
      dispatchNotification("success", I18n.t("TwoFactor.disableSuccess"));
      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        const extractedError = error.response.data as ApiError;
        dispatchNotification("error", I18n.t("TwoFactor.disableError"));
        return thunkAPI.rejectWithValue(extractedError);
      }
      dispatchNotification("error", I18n.t("TwoFactor.disableError"));
      throw error;
    }
  }
);

export const verify2FALogin = createAsyncThunk<loginResponse, Verify2FALoginArgs, AsyncThunkConfig>(
  "verify2FALogin",
  async (data, thunkAPI) => {
    try {
      const response = await client.post<ApiResponse<loginResponse>>(
        API_PATHS.TWO_FACTOR.VERIFY_LOGIN,
        data
      );
      return response.data.results;
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        const extractedError = error.response.data as ApiError;
        return thunkAPI.rejectWithValue(extractedError);
      }
      throw error;
    }
  }
);

export const verify2FA = createAsyncThunk<void, Verify2FAArgs, AsyncThunkConfig>(
  "verify2FA",
  async (data, thunkAPI) => {
    try {
      await client.post(API_PATHS.TWO_FACTOR.VERIFY, {
        tempToken: data.tempToken,
        twoFactorToken: data.twoFactorToken,
        isBackupCode: data.isBackupCode
      });
      dispatchNotification("success", I18n.t("TwoFactor.setupSuccess"));
    } catch (error) {
      if (isAxiosError(error) && error.response != null) {
        const extractedError = error.response.data as ApiError;
        dispatchNotification("error", I18n.t("TwoFactor.verificationError"));
        return thunkAPI.rejectWithValue(extractedError);
      }
      dispatchNotification("error", I18n.t("TwoFactor.verificationError"));
      throw error;
    }
  }
);

