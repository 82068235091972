import { navigate } from "gatsby-link";
import { loginPath } from "./auth";
import { MethodologyName } from "../api/examinationApi";

// request paths
export const archiveDocumentationsURL = "/api/users/archives/documents/";
export const archiveExaminationsURL = "/api/users/archives/examinations/";
export const changePasswordURL = "/api/users/password_change/";
export const changePasswordExpiredURL = "/api/users/password_change_expired/";
export const changePinURL = "/api/users/pin_change/";
export const directoriesURL = "/api/users/directories/";
export const drivingLicenseURL = "/api/users/driving-license-categories/";
export const examinationURL = "/api/core/examinations/";
export const examineesURL = "/api/users/examinees/";
export const getPsychologicalLabInfoURL = "/api/users/psych-lab-info/";
export const getResetPasswordEmailURL = "/api/users/password_reset/";
export const legalBasisURL = "/api/core/legal_bases/";
export const licensedMethodologiesURL = "/api/core/licensed_methodologies/";
export const moveExamineesURL = "/api/users/examinees/relocate-examinees/";
export const psychologicalTestsURL = "/api/core/tests/";
export const resetPasswordSendRequestURL = "/api/users/password_reset/confirm/";
export const getContractURL = "/api/users/contract/";
export const normSetURL = "/api/core/norms/";
export const psychologicalLabsGrantedURL = "/api/users/admin_labs_granted/";
export const psychologicalLabsURL = "/api/users/psychlabs/";
export const psychologicalLabsUserURL = "/api/users/psychlabs_users/";
export const psychologicalLabsActivityURL = "/api/users/psychlabs_activity/";
export const psychologicalLabsLicenceURL = "/api/users/psychlabs_license/";
export const psychologicalUserURL = "/api/users/psych_user/";
export const psychologicalMethodologyTestCategories = "/api/users/methodology/";
export const psychologicalTestTypes = "/api/users/test_types/";

// gatsby pages' paths
export const archiveExaminationPath = "archive-examination";
export const addExamineePath = "/app/add-examinee";
export const addPsychLabPath = "/app/add-psychlab";
export const addPsychLabUserPath = "/app/add-psychuser";
export const dashboardPath = "/app/dashboard";
export const dashboardAdminPath = "/app/admin-dashboard";
export const diagnosticReportPath = "/app/documentation/diagnostic-report";
export const directoriesPath = "/app/directories";
export const editExamineePath = "/app/edit-examinee";
export const examinationPath = "/app/examination";
export const examinationAssignmentPath = "/app/examination-assignment";
export const examineePath = "/app/examinee";
export const psychLabPath = "/app/psychlab";
export const editPsychUserPath = "/app/edit-psychuser/";
export const expiredPasswordPath = "/expiredPassword";
export const helpdeskPath = "/helpdesk";
export const helpdeskDocumentPath = "/app/helpdesk/documents";
export const interpretationReportPath = "/app/documentation/interpretation-report";
export const resetPasswordGetEmailPath = "/getResetPasswordEmail";
export const searchPath = "/app/search";
export const searchAdminPath = "/app/admin-search";
export const userSettingsPath = "/app/user-settings";
export const adminSettingsPath = "/app/admin-settings";
export const remoteHelpPath = "https://anydesk.com/pl/downloads/windows?dv=win_exe";

// navigation handlers
export const handleNavigateToPreviousPage = () => navigate(-1);

export const handleReloadPage = () => window.location.reload();

let isNavigating = false;

export const handleNavigateToPage = (path: string, newWindow = false) => {
  if (newWindow) {
    window.open(path, "_blank", "noreferrer");
  } else {
    isNavigating = true;
    void navigate(path);
    if (window.location.pathname === path) {
      window.location.reload();
    }
    // Resetujemy flagę po krótkim opóźnieniu
    setTimeout(() => {
      isNavigating = false;
    }, 100);
  }
};

export const setupRefreshHandler = (handleLogout: () => void) => {
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (!isNavigating) {
      handleLogout();
      event.preventDefault();
      event.returnValue = "Czy na pewno chcesz odświeżyć stronę? Zostaniesz wylogowany.";
    }
  };

  window.addEventListener("beforeunload", handleBeforeUnload);

  return () => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
  };
};
export const handleNavigateExpiredPasswordPage = (
  userId: string
) => {
  void navigate(`${expiredPasswordPath}/?userId=${userId}`);
}

export const handleNavigateToResetPage = () => {
  void navigate(resetPasswordGetEmailPath);
}

export const handleNavigateToLogin = () => {
  void navigate(loginPath);
};

export const handleNavigateToReport = (
  examineeId: number,
  examinationId: string,
  reportType: "diagnostic" | "interpretation"
) => {
  void navigate(
    `${examineePath}/${examineeId}/examination/${examinationId}/documentation/${reportType}-report/`
  );
};

export const handleNavigateToDirectoryContent = (directoryId: number) => {
  void navigate(`${directoriesPath}/${directoryId}/`);
};

export const handleNavigateToExamination = (
  examineeId: number,
  examinationId: string
) => {
  void navigate(`${examineePath}/${examineeId}/examination/${examinationId}/`);
};

export const handleNavigateToExaminationAssignment = (examineeId: number) => {
  void navigate(`${examinationAssignmentPath}/${examineeId}/`);
};

export const handleNavigateToPsychologicalTestsStep = (examineeId: number) => {
  void navigate(`${examinationAssignmentPath}/${examineeId}/tests-assignment`);
};
export const handleNavigateToAddExamineeDirectories = (): void => {
  void navigate(`${directoriesPath}/add-examinee`);
};

export const handleNavigateToAddExamineeDirectory = (
  directory?: number
): void => {
  directory && void navigate(`${directoriesPath}/${directory}/add-examinee`);
};

export const handleNavigateToEditExamineeDirectory = (
  examineeId: number,
  directory?: number
) => {
  directory &&
    void navigate(
      `${directoriesPath}/${directory}/edit-examinee/${examineeId}`
    );
};

export const handleNavigateToEditPsychLab = (psychLabId: number) => {
  void navigate(`${psychLabPath}/edit-psychlab/${psychLabId}`);
};

export const handleNavigateToAddPsychLabLic = (psychLabId: number) => {
  void navigate(`${psychLabPath}/add-psychlablic/${psychLabId}`);
};

export const handleNavigateToEditPsychLabLic = (licenseId: number) => {
  void navigate(`${psychLabPath}/edit-psychlablic/${licenseId}`);
};

export const handleNavigateToEditPsychUser = (psychUserId: number) => {
  void navigate(`${editPsychUserPath}${psychUserId}`);
};

export const handleNavigateToExamineeProfile = (examineeId: number) => {
  examineeId && void navigate(`${examineePath}/${examineeId}`);
};

export const handleNavigateToEditExamineeProfile = (examineeId: number) => {
  examineeId && void navigate(`${examineePath}/${examineeId}/edit-examinee`);
};

export const handleNavigateToPsychLabProfile = (id: number) => {
  id && void navigate(`${psychLabPath}/${id}`);
};

export const handleNavigateToDisplayArchiveExamination = (
  archiveExaminationId: string,
  examineeId: number,
  legacyId: string
) => {
  archiveExaminationId &&
    examineeId &&
    legacyId &&
    void navigate(
      `${examineePath}/${examineeId}/${archiveExaminationPath}/${legacyId}/${archiveExaminationId}`
    );
};

export const handleNavigateToDocument = (
  examineeId: number,
  examinationId: string,
  documentType: string,
  documentId: number,
  methodology?: MethodologyName
) => {
  const url = methodology ?
    `/app/examinee/${examineeId}/examination/${examinationId}/documentation/${documentType}/${documentId}/${methodology}` :
    `/app/examinee/${examineeId}/examination/${examinationId}/documentation/${documentType}/${documentId}`
  void navigate(url);
};

export const handleNavigateToInterview = (
  examineeId: number,
  examinationId: string
) => {
  examineeId &&
    examinationId &&
    void navigate(
      `${examineePath}/${examineeId}/examination/${examinationId}/interview`
    );
};

export const API_PATHS = {
  // ... existing paths ...
  TWO_FACTOR: {
    SETUP: '/api/users/setup_2fa/',
    VERIFY_SETUP: '/api/users/verify_2fa_setup/',
    DISABLE: '/api/users/disable_2fa/',
    VERIFY: '/api/users/verify_2fa/',
    VERIFY_LOGIN: '/api/users/verify-2fa/',
  },
} as const;
