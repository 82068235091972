exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adminsite-tsx": () => import("./../../../src/pages/adminsite.tsx" /* webpackChunkName: "component---src-pages-adminsite-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-expired-password-tsx": () => import("./../../../src/pages/expiredPassword.tsx" /* webpackChunkName: "component---src-pages-expired-password-tsx" */),
  "component---src-pages-get-reset-password-email-tsx": () => import("./../../../src/pages/getResetPasswordEmail.tsx" /* webpackChunkName: "component---src-pages-get-reset-password-email-tsx" */),
  "component---src-pages-helpdesk-tsx": () => import("./../../../src/pages/helpdesk.tsx" /* webpackChunkName: "component---src-pages-helpdesk-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/resetPassword.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-user-settings-index-tsx": () => import("./../../../src/pages/UserSettings/index.tsx" /* webpackChunkName: "component---src-pages-user-settings-index-tsx" */)
}

